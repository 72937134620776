import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props) => {

    const dom = $(el);
    const dismiss = dom.find('[data-dismiss]');
    const ctas = dom.find('a');
    const rootStyle = document.documentElement.style;
    const key = props.key || 'strip-banner';

    let lastBannerHeight = 0;

    console.log(props);

    const setCookie = () => {
        const stripBannerCookie = window.vrsg.getCookie(key);
        const stripBannerData = stripBannerCookie ? JSON.parse(stripBannerCookie) : [];
        stripBannerData.push(props.uid);
        window.vrsg.setCookie(key, JSON.stringify(stripBannerData), 365);
    };

    const onDismiss = () => {
        setCookie();
        const proxy = { height: lastBannerHeight };
        gsap.to(el, { duration: 1, y: '-100%', ease: 'expo.inOut' });
        gsap.to(proxy, {
            duration: 1,
            height: 0,
            ease: 'expo.inOut',
            onUpdate: () => {
                rootStyle.setProperty('--strip-banner-height', `${proxy.height}px`);
            },
            onComplete: () => {
                destroy();
                el.remove();
                rootStyle.setProperty('--strip-banner-height', '0px');
            }
        });

    };

    const onClick = () => {
        setCookie();
    };

    const onResize = () => {
        const currentBannerHeight = el.getBoundingClientRect().height;
        if (currentBannerHeight !== lastBannerHeight) {
            lastBannerHeight = currentBannerHeight;
            rootStyle.setProperty('--strip-banner-height', `${currentBannerHeight}px`);
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        dismiss.on('click', onDismiss);
        ctas.on('click', onClick);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        dismiss.off('click', onDismiss);
        ctas.off('click', onClick);
    };

    return {
        init,
        destroy
    };

};
